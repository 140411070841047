import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaStar } from "react-icons/fa";
// import Give_Review from "./give-a-review";
import { getDateAndTime } from "../utils/dates";

const Review = ({ show, setShow, ratingList }) => {
  const handleClose = () => setShow(false);
  const [givereviewShow, setGiveReviewShow] = useState(false);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="animate__animated animate__fadeInUp view-orders"
        scrollable
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 fw-600">Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-6">
                <h3 className="fw-bold">{ratingList?.averageRating?.toFixed(2)}</h3>
                <div className="star d-flex cursor">
                  <FaStar className="text-pink fs-4" />
                  <FaStar className="text-pink fs-4 ms-1" />
                  <FaStar className="text-pink fs-4 ms-1" />
                  <FaStar className="text-pink fs-4 ms-1" />
                  <FaStar className="text-grayc fs-4 ms-1" />
                </div>
                <p className="fs-14  mt-2 text-grayc">Based on {ratingList?.data?.length} review</p>
              </div>
              <div className="col-6">
                <div className="d-flex ">
                  <span className="fs-14 me-2">{ratingList?.counting?.rating_1}</span>
                  <meter
                    value={ratingList?.counting?.rating_1}
                    min="0"
                    max="5"
                    className="w-100 align-self-center"
                  ></meter>
                </div>
                <div className="d-flex">
                  <span className="fs-14 me-2">{ratingList?.counting?.rating_2}</span>
                  <meter
                    value={ratingList?.counting?.rating_2}
                    min="0"
                    max="5"
                    className="w-100 align-self-center"
                  ></meter>
                </div>
                <div className="d-flex">
                  <span className="fs-14 me-2">{ratingList?.counting?.rating_3}</span>
                  <meter
                    value={ratingList?.counting?.rating_3}
                    min="0"
                    max="5"
                    className="w-100 align-self-center"
                  ></meter>
                </div>
                <div className="d-flex">
                  <span className="fs-14 me-2">{ratingList?.counting?.rating_4}</span>
                  <meter
                    value={ratingList?.counting?.rating_4}
                    min="0"
                    max="5"
                    className="w-100 align-self-center"
                  ></meter>
                </div>
                <div className="d-flex">
                  <span className="fs-14 me-2">{ratingList?.counting?.rating_5}</span>
                  <meter
                    value={ratingList?.counting?.rating_5}
                    min="0"
                    max="5"
                    className="w-100 align-self-center"
                  ></meter>
                </div>
              </div>
            </div>
            {ratingList?.data?.length > 0 &&
              ratingList?.data.map((item) => {
               return <div className="row">
                  <div className="col-12">
                   
                    <div className="card-rating bg-lightc p-2 mt-2">
                      <p className="fs-12">
                        <FaStar className="text-pink me-1" />
                        <b className="align-middle">{item.rating}</b>
                        <span className="ms-1">{getDateAndTime(item.createdAt).formattedDate}</span>
                      </p>

                      <p className="fs-12 mb-1">{item?.comment}.
                      </p>
                      <p className="text-grayc mb-0 fs-12">{item.user?.name}</p>
                    </div>
                  </div>
                </div>;
              })}
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            className="btn btn-custom"
            onClick={() => setGiveReviewShow(true)}
          >
            Give a Review
          </Button>
        </Modal.Footer> */}
      </Modal>

      {/* <Give_Review show={givereviewShow} setShow={setGiveReviewShow} /> */}
    </>
  );
};

export default Review;
