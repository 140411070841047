import React, { useEffect, useState } from "react";
import res1 from "../assets/images/restaurant/1.png";
import { Link, useLocation } from "react-router-dom";
import { BiSolidOffer } from "react-icons/bi";
import { FaStar } from "react-icons/fa";
import { api } from '../urls'
import { requestPost } from '../utils/call';
import DataNotFound from "../components/data-not-found";
const Restaurant_List = () => {
    const location = useLocation()
    const [searchedList, setSearchedList] = useState([]);

    useEffect((e) => {
        console.log("==", location.state);

        let value = location.state?.name
        let longLat = location.state?.longLat
        if (value) {
            let clear = setTimeout(() => {
                if (value) {
                    requestPost(api.getRestaurantsByName, { search: value, ...location.state?.latLong}).then((res) => {
                        if (res.data) {
                            setSearchedList(res.data?.data)
                        } else {
                            setSearchedList([])
                        }
                    })
                }
            }, 500);
            return () => clearTimeout(clear);
        } else if (longLat?.longitude) {
            requestPost(location.state?.apiName, longLat).then(res => {
                if (res.data) {
                    setSearchedList(res.data?.data)
                } else {
                    setSearchedList([])
                }
            })

        }

    }, [location.state.name])




    return (
        <>
            <nav aria-label="breadcrumb " className="mt-2 mb-5 ps-2">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item fs-12"><Link to="/" className="text-grayc text-decoration-none">Home</Link></li>
                    <li className="breadcrumb-item active text-black fs-12" aria-current="page">Restaurant list</li>
                </ol>
            </nav>
            <section className="my-5 partners chains">
                <div className="container-fluid">
                    <div className="row justify-content-center px-4">
                        {searchedList.length > 0 ? searchedList.map((item) => {
                            return <div class='col-md-3'>
                                <Link to={`/restaurant?id=${item._id}`}>
                                    <div className="restrocard">
                                        <span className="off"><BiSolidOffer size={20} /><span className="ms-1">70% off</span></span>
                                        <img src={res1} alt="" className="img-fluid" />

                                        <div className="d-flex justify-content-around pt-2">
                                            <div>
                                                <p className="restroname  mb-0">{item.name}</p>
                                                <p className="menu-p">{item.address}</p>
                                            </div>
                                            <div className="text-right">
                                                <p className="restroreview mb-1"><span>4.3</span> <FaStar className="fs-6" /></p>
                                                <p className="price-p">Price Range ₹250-₹550</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </div>
                        }) : <DataNotFound />}




                    </div>


                </div>
            </section>


        </>
    )
}

export default Restaurant_List;